import { createSlice } from "@reduxjs/toolkit";

const IndexPeriod: any = {
    copyIndex: -1,
    principalIndex: 0,
};

const initialState = { value: IndexPeriod };
export const indexPeriodSlice = createSlice({
    name: "IndexPeriodTable",
    initialState,
    reducers: {
        IndexPeriodTable: (state: any, action: any) => {
            state.value = action.payload;
        },
    },
});

export const { IndexPeriodTable } = indexPeriodSlice.actions;

export default indexPeriodSlice.reducer;
