import { createSlice } from "@reduxjs/toolkit";

const schemaAdbFranchise: any = localStorage.getItem("schemaAdbFranchise");

type franchiseNameSliceType = {
    name: string;
};

const initialState: franchiseNameSliceType = { name: schemaAdbFranchise };
export const franchiseNameSlice = createSlice({
    name: "franchiseNameSlice",
    initialState,
    reducers: {
        setNewFranchise: (state: any, action: any) => {
            state.name = action.payload;
        },
    },
});

export const { setNewFranchise } = franchiseNameSlice.actions;

export default franchiseNameSlice.reducer;
