import { createSlice } from "@reduxjs/toolkit";

type selectedCardSliceStateType = {
    selectedCardIuud: string;
};

const initialState: selectedCardSliceStateType = {
    selectedCardIuud: JSON.parse(localStorage.getItem("selectedCard") || "{}")
        .iuud,
};
export const cardSlice = createSlice({
    name: "Card",
    initialState,
    reducers: {
        setSelectedCard: (state, action) => {
            state.selectedCardIuud = action.payload;
        },
    },
});

export const { setSelectedCard } = cardSlice.actions;

export default cardSlice.reducer;
