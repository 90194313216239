import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

type StepSelectionDropdownElementsType = {
    categories: { iuud: string; category: CategoryType }[];
    steps: { iuud: string; step: StepType }[];
    workflows: { iuud: string; designation: string }[];
};

type WorkFlowStepStateType = {
    workflow: WorkFlowStepProps[];
    stepSelectionDropdownElements: StepSelectionDropdownElementsType;
    elementsTree: {
        categories: CategoryWithChildrenType[];
        steps: StepWithChildrenType[];
    };
};

const initialState: WorkFlowStepStateType = {
    workflow: [],
    elementsTree: {
        categories: [],
        steps: [],
    },
    stepSelectionDropdownElements: {
        categories: [],
        steps: [],
        workflows: [],
    },
};

export const workFlowSlice = createSlice({
    name: "Workflow",
    initialState,
    reducers: {
        setWorkflow: (state, action) => {
            state.workflow = action.payload;
        },
        setElementsTree: (state, action) => {
            state.elementsTree = action.payload;
        },
        setStepSelectionDropdownElements: (state, action) => {
            state.stepSelectionDropdownElements = action.payload;
        },
    },
});

export const setElementsTreeAsync = () => async (dispatch: Function) => {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");

    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;

    axios
        .get(
            `${process.env.REACT_APP_ADB_API_URL}/${schemaAdbFranchise}/cards/${cardIuud}/workflows/elements-tree`
        )
        .then((elements) => {
            dispatch(
                setElementsTree({
                    categories: elements.data.categories,
                    steps: elements.data.steps,
                })
            );
        });
};
export const setStepSelectionDropdownElementsAsync =
    () => async (dispatch: Function) => {
        const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");

        const cardIuud = JSON.parse(
            localStorage.getItem("selectedCard") || "{}"
        ).iuud;

        axios
            .get(
                `${process.env.REACT_APP_ADB_API_URL}/${schemaAdbFranchise}/cards/${cardIuud}/workflows/elements`
            )
            .then((stepElements) => {
                dispatch(setStepSelectionDropdownElements(stepElements.data));
            });
    };

export const {
    setWorkflow,
    setElementsTree,
    setStepSelectionDropdownElements,
} = workFlowSlice.actions;
export default workFlowSlice.reducer;
