import { createSlice } from "@reduxjs/toolkit";

const CopyHour: any = {
    HeureDebut: "",
    HeureFin: "",
    Price: "",
};

const initialState = { value: CopyHour };
export const copyHourSlice = createSlice({
    name: "copyHourTable",
    initialState,
    reducers: {
        copyHourTable: (state: any, action: any) => {
            state.value = action.payload;
        },
    },
});

export const { copyHourTable } = copyHourSlice.actions;

export default copyHourSlice.reducer;
