import { createSlice } from "@reduxjs/toolkit";

const CopyDate: any = {
    DateDebut: "",
    DateFin: "",
};

const initialState = { value: CopyDate };
export const copyDateSlice = createSlice({
    name: "copyDateTable",
    initialState,
    reducers: {
        copyDateTable: (state: any, action: any) => {
            state.value = action.payload;
        },
    },
});

export const { copyDateTable } = copyDateSlice.actions;

export default copyDateSlice.reducer;
