import { createSlice } from "@reduxjs/toolkit";

type WorkFlowDataStateType = {
    workflowData: {};
};

const initialState: WorkFlowDataStateType = {
    workflowData: {},
};

export const workFlowDataSlice = createSlice({
    name: "WorkflowData",
    initialState,
    reducers: {
        setWorkflowData: (state, action) => {
            state.workflowData = action.payload;
        },
    },
});

export const { setWorkflowData } = workFlowDataSlice.actions;
export default workFlowDataSlice.reducer;
