// createSlice create our reducer simlpy

import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: {} };
export const clientSlice = createSlice({
    name: "client",
    initialState,
    reducers: {
        selectedClient: (state: any, action: any) => {
            state.value = action.payload;
        },
    },
});

export const { selectedClient } = clientSlice.actions;

export default clientSlice.reducer;
