import { createSlice } from "@reduxjs/toolkit";

type AllergenStateType = {
    allergens: { iuud: string; allergen: AllergenType }[];
};

const initialState: AllergenStateType = {
    allergens: [],
};

export const allergenSlice = createSlice({
    name: "allergens",
    initialState,
    reducers: {
        setAllergens: (state: any, action: any) => {
            state.allergens = action.payload;
        },
    },
});

export const setAllergensAsync = () => async (dispatch: Function) => {
    const schemaAdbFranchise = localStorage.getItem("schemaAdbFranchise");

    const cardIuud = JSON.parse(
        localStorage.getItem("selectedCard") || "{}"
    ).iuud;

    const response = await fetch(
        `${process.env.REACT_APP_ADB_API_URL}/${schemaAdbFranchise}/cards/${cardIuud}/allergens`
    );
    const data = await response.json();

    const allergensList: { iuud: string; allergen: AllergenType }[] = data;
    dispatch(setAllergens(allergensList));
};

export const { setAllergens } = allergenSlice.actions;

export default allergenSlice.reducer;
