import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: { isAddingCatOpen: false, category: {} },
};

export const categorySlice = createSlice({
    name: "category",
    initialState,
    reducers: {
        selectedCategory: (state: any, action: any) => {
            state.value = action.payload;
        },
    },
});

export const { selectedCategory } = categorySlice.actions;

export default categorySlice.reducer;
