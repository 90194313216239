import { createSlice } from "@reduxjs/toolkit";

type UpdateItemWorkflowStateType = {
    updateItem: {
        pathUpdateItem: string[];
        indexWorkflowGlobal: number;
        uuid: string;
        isModal: boolean;
        previousModifier: string;
        uuidStep: string;
    };
};

const initialState: UpdateItemWorkflowStateType = {
    updateItem: {
        pathUpdateItem: [],
        indexWorkflowGlobal: -1,
        uuid: "",
        isModal: false,
        previousModifier: "",
        uuidStep: "",
    },
};

export const updateItemWorkflowSlice = createSlice({
    name: "updateItemWorkflow",
    initialState,
    reducers: {
        setUpdateItemWorkflow: (state, action) => {
            state.updateItem = action.payload;
        },
        resetUpdateItemWorkflow: (state) => {
            state.updateItem = initialState.updateItem;
        },
    },
});

export const { setUpdateItemWorkflow, resetUpdateItemWorkflow } =
    updateItemWorkflowSlice.actions;
export default updateItemWorkflowSlice.reducer;
