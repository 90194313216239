import { createSlice } from "@reduxjs/toolkit";

const cardExchange: any = {
    Card: {},
    Iuud: "",
};

const initialState = { value: cardExchange };
export const cardExchangeSlice = createSlice({
    name: "Card",
    initialState,
    reducers: {
        setSelectedExchangeCard: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { setSelectedExchangeCard } = cardExchangeSlice.actions;

export default cardExchangeSlice.reducer;
