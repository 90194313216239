import { createSlice } from "@reduxjs/toolkit";

const initialState: any = ["dddd"];
export const parentCategorieSlice = createSlice({
    name: "article",
    initialState,
    reducers: {
        parentCategorie: (state: any, action: any) => {
            state = action.payload;
        },
    },
});

export const { parentCategorie } = parentCategorieSlice.actions;

export default parentCategorieSlice.reducer;
