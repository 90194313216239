import { createSlice } from "@reduxjs/toolkit";

type setmultipleWorkflowStateType = {
    multipleWorkflow: multipleItemWorkflowProps[];
};

const initialState: setmultipleWorkflowStateType = {
    multipleWorkflow: [],
};

export const multipleWorkflowSlice = createSlice({
    name: "multipleWorkflow",
    initialState,
    reducers: {
        setMultipleWorkflow: (state, action) => {
            state.multipleWorkflow = action.payload;
        },
    },
});

export const { setMultipleWorkflow } = multipleWorkflowSlice.actions;
export default multipleWorkflowSlice.reducer;
