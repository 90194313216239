import { createSlice } from "@reduxjs/toolkit";

type WorkFlowModifierStateType = {
    modifier: Record<
        string,
        {
            "uuid-iem": string;
            steps: Record<
                string,
                {
                    overrides: any;
                    itemModifiers: any;
                }
            >;
            add: any;
            category: any;
            typeSteps: boolean;
            typeAdd: boolean;
            typeCategorie: boolean;
        }
    >;
};

const initialState: WorkFlowModifierStateType = {
    modifier: {},
};

export const modifierSlice = createSlice({
    name: "Modifier",
    initialState,
    reducers: {
        setModifier: (state, action) => {
            state.modifier = action.payload;
        },
    },
});

export const { setModifier } = modifierSlice.actions;
export default modifierSlice.reducer;
