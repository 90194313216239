import { createSlice } from "@reduxjs/toolkit";

type initialStateProps = {
    listkey: string[];
    isMultiModify: boolean;
};
const initialState: initialStateProps = { listkey: [], isMultiModify: false };
export const multipleEditSlice = createSlice({
    name: "listKeytoEdit",
    initialState,
    reducers: {
        addListKeytoEdit: (state: any, action: any) => {
            const found = state.listkey.find(
                (key: string) => key === action.payload
            );

            !found &&
                action.payload !== "" &&
                state.listkey.push(action.payload);
        },
        isMultiModify: (state: any) => {
            state.isMultiModify = true;
        },

        resetMultipleEditSlice: (state) => {
            state.listkey = [];
            state.isMultiModify = false;
        },
    },
});

export const { addListKeytoEdit, resetMultipleEditSlice, isMultiModify } =
    multipleEditSlice.actions;

export default multipleEditSlice.reducer;
